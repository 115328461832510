<!-- src/pages/ChatPage.vue -->

<template>
  <v-navigation-drawer 
    expand-on-hover 
    rail
    class="navigation-drawer"
    rail-width="64"
    width="350"
    v-if="!$vuetify.display.mobile"
  >
    <ChatNavigationContent
      :userPhoto="userPhoto"
      :userName="userName"
      :truncatedUserEmail="truncatedUserEmail"
      :flights="flights"
      :newLessonsAvailable="newLessonsAvailable"
      :isPaidUser="isPaidUser"
      @reset-chat="resetChat"
      @open-chat-history="openChatHistory"
      @open-custom-files="openCustomFiles"
      @open-flight-lessons="openFlightLessons"
      @open-assistant-info="openAssistantInfo"
      @open-billing-portal="openBillingPortal"
      @open-user-profile="openUserProfile"
      @sign-out="signOut"
    />  
  </v-navigation-drawer>
  
  <v-app-bar v-if="isProfessionalUser || $vuetify.display.mobile" class="top-navigation-bar" density="compact">
    <v-row class="d-flex align-center">
      <v-col cols="2" class="d-flex justify-center">
        <v-menu
          v-model="menu"
          location="end"
          v-if="$vuetify.display.mobile"
        >
          <template v-slot:activator="{ props }">
            <v-btn icon color="primary" v-bind="props">
              <v-icon>mdi-menu</v-icon>
            </v-btn>
          </template>
          <v-card class="menu-card" width="350">
            <ChatNavigationContent
              :userPhoto="userPhoto"
              :userName="userName"
              :truncatedUserEmail="truncatedUserEmail"
              :flights="flights"
              :newLessonsAvailable="newLessonsAvailable"
              :isPaidUser="isPaidUser"
              @reset-chat="resetChat"
              @open-chat-history="openChatHistory"
              @open-custom-files="openCustomFiles"
              @open-flight-lessons="openFlightLessons"
              @open-assistant-info="openAssistantInfo"
              @open-billing-portal="openBillingPortal"
              @open-user-profile="openUserProfile"
              @sign-out="signOut"
            /> 
          </v-card>
        </v-menu>
      </v-col>
      <v-col cols="8" class="d-flex justify-center">
        <v-btn v-if="isProfessionalUser" rounded="xl" class="navigation-button" variant="tonal">Chat</v-btn>
        <v-btn 
          v-if="isProfessionalUser"
          rounded="xl" 
          class="navigation-button" 
          @click="handleInstructClick"
        >
          Instruct
        </v-btn>
      </v-col>
    </v-row>
  </v-app-bar>

  <!-- Basic trial banner -->
  <v-banner
    v-if="isBasicUser && !isPaidUser && !isLoadingUser && !isLoading"
    lines="one"
    bg-color="warning"
    density="compact"
  >
    <template v-slot:text>
      <div class="trial-item">
        Only {{ trialDaysRemaining }} day(s) left in your Basic trial!
      </div>
    </template>
    <template v-slot:prepend>
      <v-icon class="trial-item">mdi-clock-alert</v-icon>
    </template>
    <template v-slot:actions>
      <v-btn class="trial-item" @click="openCheckoutOverlay()">Subscribe</v-btn>
    </template>
  </v-banner>

  <!-- Professional trial banner -->
  <v-banner
    v-if="isProfessionalUser && !isPaidUser && !isLoadingUser && !isLoading"
    lines="one"
    bg-color="warning"
    density="compact"
  >
    <template v-slot:text>
      <div class="trial-item">
        Only {{ trialDaysRemaining }} day(s) left in your Professional trial!
      </div>
    </template>
    <template v-slot:prepend>
      <v-icon class="trial-item">mdi-clock-alert</v-icon>
    </template>
    <template v-slot:actions>
      <v-btn class="trial-item" @click="openCheckoutOverlay()">Subscribe</v-btn>
    </template>
  </v-banner>

  <!-- Loading indicator placeholder -->
  <v-container v-if="isLoading" class="fill-height d-flex align-center justify-center">
    <v-row justify="center">
      <v-col cols="12" class="text-center">
        <v-progress-circular
          indeterminate
          color="primary"
          size="100"
          width="5"
      > <v-icon>mdi-airplane</v-icon>
      </v-progress-circular>
      </v-col>
    </v-row>
  </v-container>

  <v-container
    v-if="!isLoading"
    :class="{
      'main-container-subscribe': !isPaidUser && !isBasicUser && !isProfessionalUser, 
      'main-container-basic-trial': isFreeTrial && isBasicUser,
      'main-container-professional-trial': isFreeTrial && isProfessionalUser,  
      'main-container-basic': !isFreeTrial && isBasicUser, 
      'main-container-professional': !isFreeTrial && isProfessionalUser
      }" 
    class="custom-background"
  >
    <!-- Default content when chat is not active -->
    <v-row dense v-if="!isChatActive" class="default-container">
      <v-col cols="12" class="justify-center">
        <v-img src="@/assets/orb-animation.png" class="breathing-image" />
      </v-col>
      <v-col cols="12" align="center">
        <v-card-title class="display-title">Hi, I'm Amelia...</v-card-title>
        <v-card-text class="display-text">Your personal aviation knowledge assisant.</v-card-text>
      </v-col>
      
      <!-- Sample prompts -->
      <v-col 
        cols="12" 
        align="center" 
        class="prompt-row" 
        v-for="(prompt, index) in samplePrompts" 
        :key="index"
      >
        <v-btn
          color="primary"
          :disabled="!assistantId"
          variant="tonal"
          class="text-subtitle-1 prompt-button"
          @click="startChatWithPrompt(prompt)"
          v-if="!$vuetify.display.mobile"
        >
          {{ prompt }}
        </v-btn>
      </v-col>
    </v-row>

    <v-spacer v-if="!isChatActive"/>

    <!-- Message contents when chat is active -->
    <v-row v-else class="message-container" ref="messageContainer">
      <v-col>
        <div v-for="(message, index) in conversation" :key="index" 
          class="message-item" 
          :class="{
            'user-message': message.type === 'user', 
            'assistant-message': message.type === 'assistant',
            'thinking-message': message.type === 'assistant' && message.content === '' && index === activeConversationResponseIndex
          }">
        <!-- Conditionally display the user's avatar -->
        <img v-if="message.type === 'user'" :src="userPhoto || defaultAvatar" class="avatar user-avatar"/>
        <!-- Message content -->
        <div class="message-box" 
            :class="{
              'user-box': message.type === 'user', 
              'assistant-box': message.type === 'assistant', 
              'thinking-box': message.type === 'assistant' && message.content === '' && index === activeConversationResponseIndex
            }">
          <!-- Check if the assistant is thinking and this is the placeholder message -->
          <div v-if="message.type === 'assistant' && message.content === '' && index === activeConversationResponseIndex" class="typing">
            <span></span>
            <span></span>
            <span></span>
          </div>
          <!-- Render message content if available -->
          <div v-else v-html="parseMarkdown(message.content)"></div>
          <v-btn
            v-if="message.type === 'assistant' && activeConversationResponseIndex !== index"
            class="mb-2 ml-3"
            variant="plain"
            color="error"
            size="x-small"
            text="Report as Inaccurate"
            prepend-icon="mdi-alert"
            @click="reportResponse(message)"
          >
          </v-btn>
        </div>
        <!-- Conditionally display the assistant's avatar -->
        <img v-if="message.type === 'assistant'" :src="assistantAvatar" class="avatar assistant-avatar"/>
      </div>
      </v-col>
    </v-row>

    <!-- Text input area -->
    <v-row dense class="input-container">
      <v-col cols="12">
        <div class="textarea-container">
          <div class="flex-container">
            <v-textarea
              v-model="messageContent"
              class="text-area"
              variant="solo"
              bg-color="rgba(200, 200, 200, 0.2)"
              rounded="xl"
              rows="1"
              no-resize
              hide-details
              flat
              :placeholder="assistantId ? 'Type your message...' : 'Please wait, your assistant is being created...'"
              :disabled="isAssistantThinking || !assistantId"
              @keydown.enter.prevent="handleMessageSend"
            >
            <template v-slot:append-inner>
              <v-btn
                variant="flat"
                color="primary"
                size="x-small"
                rounded="pill"
                :disabled="isSendDisabled || !assistantId"
                class="send-button"
                @click="handleMessageSend"
              >
                <v-icon color="white">mdi-send</v-icon>
              </v-btn>
            </template>
          </v-textarea>
          </div>
        </div>
      </v-col>
      <v-col cols="12">
        <v-card-text class="subtext-content">Amelia can make mistakes. Consider checking important information.</v-card-text>
      </v-col>
    </v-row>  
  </v-container>

  <!-- Loading indicator for checkout -->
  <v-overlay v-model="isLoadingCheckout" class="align-center justify-center" persistent>
    <v-progress-circular
      color="primary"
      size="64"
      indeterminate
      class="checkout-loading"
    ></v-progress-circular>
  </v-overlay>

  <!-- Subscription required overlay -->
  <v-overlay 
    v-model="subscriptionRequired"
    persistent
    class="subscription-overlay"
  >
    <v-card class="subscription-card text-center">
      <v-card-title class="my-2">Subscription Required</v-card-title>
      <v-card-text>
        We're thrilled to see you again! Unfortunately, your free trial or current subscription has come to an end. To continue enjoying all the features and benefits, please renew or update your subscription. We can't wait to have you back on board!
      </v-card-text>
      <v-row justify="center">
        <v-btn class="subscribe-button" @click="openCheckoutOverlay()">Subscribe</v-btn>
      </v-row>
      <v-row justify="center">
        <v-btn class="logout-button" variant="text" prepend-icon="mdi-logout" @click="signOut()">
          Logout
        </v-btn>
      </v-row>
    </v-card>
  </v-overlay>

  <!-- Subscription overdue overlay -->
  <v-overlay 
    v-model="isSubscriptionOverdue"
    persistent
    class="subscription-overlay"
  >
    <v-card class="subscription-card text-center">
      <v-card-title class="my-2">Subscription Payment Overdue</v-card-title>
      <v-card-text>
        Unfortunately, your payment method has failed. To continue enjoying all the features and benefits, please update your payment method. We can't wait to have you back on board!
      </v-card-text>
      <v-card-text class="pt-0 font-weight-medium">
        If you do nothing, your subscription will automatically cancel.
      </v-card-text>
      <v-row justify="center">
        <v-btn class="subscribe-button" @click="openBillingPortal()">Billing Portal</v-btn>
      </v-row>
      <v-row justify="center">
        <v-btn class="logout-button" variant="text" prepend-icon="mdi-logout" @click="signOut()">
          Logout
        </v-btn>
      </v-row>
    </v-card>
  </v-overlay>

  <!-- System initializing overlay -->
  <v-overlay v-model="showInitializationOverlay" persistent class="initializing-overlay">
    <v-card class="initializing-card text-center" variant="flat">
      <v-progress-circular
          indeterminate
          color="background"
          size="100"
          width="5"
      > <v-icon>mdi-airplane</v-icon>
      </v-progress-circular>
      <v-card-title class="my-2">Getting things ready...</v-card-title>
      <v-card-text>
        Please wait while we prepare everything for your session.
      </v-card-text>
    </v-card>
  </v-overlay>

  <SnackbarNotification 
    :message="snackbar.message" 
    :color="snackbar.color" 
    :timeout="snackbar.timeout" 
    location="top right"
  />

  <!-- Page components -->
  <ChatHistory ref="chatHistory" @chatSelected="loadChatThread" />
  <CustomFiles 
    ref="customFiles" 
    @customFileUploaded="() => showSnackbar('Custom file added', 'success')"
    @customFileDeleted="() => showSnackbar('Custom file deleted', 'info')"
    @customFileUploadError="() => showSnackbar('Error adding custom file', 'error')"
    @customFileDeleteError="() => showSnackbar('Error deleting custom file', 'error')"
   />
  <AssistantInfo ref="assistantInfo" />
  <StudentLessons ref="studentLessons" />
  <UserProfile ref="userProfile" @profileUpdated="() => showSnackbar('User profile updated', 'info')"/>
  <CheckoutOverlay ref="checkoutOverlay" @initiateCheckout="handleCheckout" @initiateProfessionalTrialCheckout="handleProfessionalTrialCheckout" />
</template>

<script>
import { 
  fetchStudentFlights,
  fetchLastCheckedFlights, 
  updateLastCheckedFlights,
  reportInaccurateResponse,
  waitForSubscriptionCreation
} from '@/services/firebaseService';
import { createCheckoutSession, createProfessionalTrialSubscription, listenToSession } from '@/services/stripeService';
import { Sentry } from '@/plugins/sentry.js';
import { analytics } from '../../firebaseConfig';
import { logEvent } from 'firebase/analytics';
import SnackbarNotification from '@/components/SnackbarNotification.vue';
import ChatNavigationContent from '@/components/ChatNavigationContent.vue';
import ChatHistory from '@/components/ChatHistory.vue';
import CustomFiles from '@/components/CustomFiles.vue';
import UserProfile from "@/components/UserProfile.vue";
import StudentLessons from "@/components/StudentLessons.vue";
import CheckoutOverlay from  "@/components/CheckoutOverlay.vue";
import AssistantInfo from "@/components/AssistantInfoOverlay.vue";
import MarkdownIt from 'markdown-it';
import { v4 as uuidv4 } from 'uuid';
import { postThread, postChatMessage, getChatStream, getThread } from '@/services/api';

export default {
  name: 'ChatPage',
  components: {
    SnackbarNotification,
    ChatNavigationContent,
    ChatHistory,
    CustomFiles,
    AssistantInfo,
    UserProfile,
    CheckoutOverlay,
    StudentLessons
  },
  data () {
    return {
      defaultAvatar: require('@/assets/default-avatar.png'),
      assistantAvatar: require('@/assets/orb-animation.png'),
      // Chat info
      isChatActive: false,
      isAssistantThinking: false,
      messageContent: '',
      threadId: null,
      conversation: [],
      // Other info
      snackbar: {
        message: '',
        color: '',
        timeout: null,
      },
      samplePrompts: [
        "Can you explain the VFR weather minimums for flying in Class B airspace?",
        "Could you provide the requirements for a pilot to remain current in instrument flying according to the FARs?",
        "What are the principles of flight for a fixed-wing aircraft?"
      ],
      isLoading: false,
      isLoadingCheckout: false,
      menu: false,
      // Flight lesson info
      flights: {},
      newLessonsAvailable: false,
      activeConversationResponseIndex: -1
    }
  },
  async created() {
    this.isLoading = true;
    await this.$store.dispatch('fetchUser');
    await this.fetchFlights();
    this.isLoading = false;
  },
  methods: {

    // CHAT INTERACTION METHODS //

    async handleMessageSend() {
      if (this.isSendDisabled) {
        return;
      }
      if (!this.isChatActive) {
        await this.initiateNewChat();
      }
      else {
        // If a chat is already active, send the message using the current thread ID
        this.sendChatMessage(this.messageContent, this.threadId);
      }
    },
    async initiateNewChat() {
      if (this.messageContent.trim() && !this.isChatActive) {
        this.isChatActive = true;
        this.isAssistantThinking = true;
        try {
          // Store the current message content in a constant
          const userMessage = this.messageContent;

          // Clear the textarea immediately
          this.messageContent = '';

          // Update the conversation array
          this.conversation.push({
            type: 'user',
            content: userMessage
          });
          this.activeConversationResponseIndex = this.conversation.push({
            type: 'assistant',
            content: ''
          }) - 1;

          const threadRes = await postThread();
          const threadUid = threadRes.threadUid;
          this.threadId = threadUid;
          await postChatMessage(threadUid, userMessage);
          await getChatStream(threadUid, this);
          logEvent(analytics, 'new_chat_initiated', { userId: this.userUid });
        } catch (error) {
          Sentry.captureMessage("Chat initiation failed");
          Sentry.captureException(error);
          console.error("Chat initiation failed:", error);
          this.showSnackbar('Failed to initiate chat. Please try again.', 'error');
        } finally {
          this.isAssistantThinking = false;
          this.activeConversationResponseIndex = -1;
        }
      }
    },
    async sendChatMessage(messageContent, threadUid) {
      if (!messageContent.trim() || !threadUid) return;
      this.isChatActive = true;
      this.isAssistantThinking = true;
      try {
        // Store the current message content in a constant
        const userMessage = this.messageContent;

        // Clear the textarea immediately
        this.messageContent = '';

        // Update the conversation array
        this.conversation.push({
          type: 'user',
          content: userMessage
        });
        this.activeConversationResponseIndex = this.conversation.push({
          type: 'assistant',
          content: ''
        }) - 1;

        await postChatMessage(threadUid, userMessage);
        await getChatStream(threadUid, this);
        logEvent(analytics, 'user_chat_sent', { userId: this.userUid });
      } catch (error) {
        Sentry.captureMessage("Error sending chat message");
        Sentry.captureException(error);
        console.error('Error sending chat message:', error);
        this.showSnackbar('Failed to send message. Please try again.', 'error');
      } finally {
        this.isAssistantThinking = false;
        this.activeConversationResponseIndex = -1;
      }
    },
    async startChatWithPrompt(prompt) {
      this.isAssistantThinking = true;
      this.messageContent = prompt;
      await this.initiateNewChat();
      logEvent(analytics, 'chat_initiated_with_prompt', { userId: this.userUid });
    },
    resetChat() {
      if (this.isChatActive) {
        this.isChatActive = false;
        this.messageContent = '';
        this.conversation = [];
        this.threadId = null;
        this.isAssistantThinking = false;
      } else {
        this.showSnackbar("New chat already started. Please begin your conversation.", "info");
      }
    },
    async fetchAndDisplayMessages(threadId) {
      try {
        const thread = await getThread(threadId);
        
        // Append new messages to the conversation
        thread.messages.forEach(message => {
          this.conversation.push({
            type: message.type,
            content: message.message
          });
        });
        
        // Scroll to the bottom after new messages are added
        this.$nextTick(() => {
          if(!this.$refs.messageContainer) return;
          const container = this.$refs.messageContainer.$el;
          container.scrollTop = container.scrollHeight;
        });
      } catch (error) {
        console.error('Error fetching messages from Firestore:', error);
        this.showSnackbar('Error updating conversation from database. Please try again.', 'error');
        this.isAssistantThinking = false;
      }
    },
    async loadChatThread(threadId) {
      this.isChatActive = true;
      this.threadId = threadId;
      this.conversation = []; // Reset the conversation before loading new messages
      this.citationMap = {}; // Reset the citation map
      this.annotationCount = 0; // Reset annotation count

      try {
        await this.fetchAndDisplayMessages(threadId);
      } catch (error) {
        console.error('Error loading chat thread:', error);
        this.snackbar.message = 'Failed to load chat history. Please try again.';
        this.snackbar.color = 'error';
        this.snackbar.timeout = 5000;
      }
    },

    // UI AND MISC METHODS //

    async fetchFlights () {
      const flightLessons = await fetchStudentFlights(this.userUid);
      if (flightLessons) {
        this.flights = flightLessons;
        this.checkForNewLessons();
      } else {
        console.log("No flight lessons found for student")
      }
    },
    async checkForNewLessons() {
      const lastChecked = await fetchLastCheckedFlights(this.userUid);
      
      if (this.flights.length === 0 || !lastChecked) {
        this.newLessonsAvailable = false;
        return;
      }
      
      const latestLessonTimestamp = new Date(this.flights[0].timestamp.seconds * 1000);
      this.newLessonsAvailable = latestLessonTimestamp > lastChecked;
    },
    async reportResponse(message) {
      const reportId = uuidv4();
      try {
        await reportInaccurateResponse(reportId, this.threadId, message, this.userUid);
        this.showSnackbar('Inaccurate response reported to FlightSense', 'info');
      } catch (error) {
        this.showSnackbar('Failed to report inaccurate response', 'error');
      }
    },
    parseMarkdown(text) {
      if (!text) return '';

      const md = new MarkdownIt().use(require('markdown-it-footnote'));

      // Custom footnote rendering rules (these remain the same)
      md.renderer.rules.footnote_ref = (tokens, idx) => {
        const n = Number(tokens[idx].meta.id + 1).toString();
        const id = 'fn' + n;
        return `<sup id="fnref${n}" class="footnote-ref"><a href="#${id}">[${n}]</a></sup>`;
      };

      md.renderer.rules.footnote_anchor = (tokens, idx) => {
        const n = Number(tokens[idx].meta.id + 1).toString();
        return ` <a href="#fnref${n}" class="footnote-backref">↩</a>`;
      };

      md.renderer.rules.footnote_anchor = () => '';
      md.renderer.rules.footnote_close = () => '</li>';
      md.renderer.rules.footnote_block_open = () => (
        `<details class="footnotes"><summary><i class="mdi mdi-book-open-page-variant"></i> View Citations</summary><section class="footnotes-content"><ol class="footnotes-list">\n`
      );
      md.renderer.rules.footnote_block_close = () => '</ol></section></details>\n';

      // Preserve LaTeX by replacing '\\[' and '\\]' with safe LaTeX delimiters
      const properlyEscapedText = text
        .replace(/\\\[/g, '\n\\\\[')  // Replace '\[' with '\\[' for MathJax
        .replace(/\\\]/g, '\\\\]\n')  // Replace '\]' with '\\]' for MathJax
        .replace(/%/g, '\\%')       // Escape percentage signs to avoid LaTeX comment behavior
        .replace(/_/g, '\\_')       // Escape underscores in LaTeX variable names like \rho_0
        .replace(/\\\(/g, '\\\\(')  // Ensure inline LaTeX \( ... \) is properly escaped
        .replace(/\\\)/g, '\\\\)'); // Ensure inline LaTeX \( ... \) is properly escaped

      // Parse the markdown content
      const renderedContent = md.render(properlyEscapedText);

      // Trigger MathJax to process LaTeX content after rendering
      this.$nextTick(() => {
        if (window.MathJax) {
          window.MathJax.typeset();
        }
      });

      return renderedContent;
    },
    showSnackbar(message, color) {
      this.snackbar.message = '';
      this.$nextTick(() => {
        this.snackbar.message = message;
        this.snackbar.color = color;
        this.snackbar.timeout = 8000;
      });
    },
    async handleCheckout(priceId) {
      this.isLoadingCheckout = true;
      try {
        const sessionId = await createCheckoutSession(this.userUid, priceId);

        const unsubscribe = listenToSession(this.userUid, sessionId, (url) => {
          window.location.href = url;
          unsubscribe();
        });
      } catch (error) {
        console.error("Failed to handle checkout:", error);
        this.showSnackbar("An error occured during checkout. Please try again.", "error");
      }
    },
    async handleProfessionalTrialCheckout(priceId) {
      this.isLoadingCheckout = true;
      try {
        const subscriptionId = await createProfessionalTrialSubscription(this.userUid, priceId);

        await waitForSubscriptionCreation(this.userUid, subscriptionId);

        this.isLoadingCheckout = false;
        window.location.reload();

      } catch (error) {
        console.error("Failed to handle checkout:", error);
        this.showSnackbar("An error occurred during checkout. Please try again.", "error");
        this.isLoadingCheckout = false;
      }
    },
    isTrialExpiredOrNotFirstDay() {
      const today = new Date();
      const createdDate = this.dateCreated;
      const isSameDay = createdDate && today.toDateString() === createdDate.toDateString();
      
      return !this.isPaidUser && this.trialDaysRemaining === 0 && !isSameDay;
    },

    // NAVIGATION METHODS //

    handleInstructClick(event) {
      if (event.metaKey || event.ctrlKey) {
        window.open('/instruct', '_blank');
      } else {
        this.$router.push('/instruct');
      }
    },
    async signOut() {
      try {
        await this.$store.dispatch('signOut');
        this.$router.push('/');
      } catch (error) {
        console.error('Error signing out:', error);
      }
    },
    openChatHistory() {
      this.$refs.chatHistory.open(this.userUid);
    },
    async openFlightLessons () {
      await updateLastCheckedFlights(this.userUid);
      this.newLessonsAvailable = false;
      this.$refs.studentLessons.open(this.flights);
    },
    openCustomFiles() {
      this.$refs.customFiles.open(this.userUid);
    },
    openAssistantInfo() {
      this.$refs.assistantInfo.open();
    },
    openUserProfile() {
      this.$refs.userProfile.open(this.userUid);
    },
    openCheckoutOverlay() {
      this.$refs.checkoutOverlay.open(this.hasHadProfessionalSubscription);
    },
    async openBillingPortal() {
      const billingPortalUrl = process.env.VUE_APP_CUSTOMER_PORTAL;
      window.open(billingPortalUrl, '_blank');
    }
  },
  computed: {
    isLoadingUser() {
      return this.$store.getters.isLoadingUser;
    },
    user() {
      return this.$store.getters.user;
    },
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    userUid() {
      return this.$store.state.user ? this.$store.state.user.uid : null;
    },
    userEmail() {
      return this.$store.state.user ? this.$store.state.user.email : '';
    },
    userName() {
      return this.$store.state.user ? this.$store.state.user.displayName : '';
    },
    userPhoto() {
      return this.$store.state.user && this.$store.state.user.photoURL ? this.$store.state.user.photoURL : this.defaultAvatar;
    },
    assistantId() {
      return this.$store.state.user ? this.$store.state.user.assistantId : '';
    },
    userToken() {
      return this.$store.state.user ? this.$store.state.user.accessToken : '';
    },
    isPaidUser() {
      return this.$store.state.user ? this.$store.state.user.isPaidUser : false;
    },
    userProduct() {
      return this.$store.state.user && this.$store.state.user.product ? this.$store.state.user.product : [];
    },
    isBasicUser() {
      return this.userProduct === 'Basic';
    },
    isProfessionalUser() {
      return this.userProduct === 'Professional';
    },
    isFreeTrial() {
      return this.$store.state.user ? this.$store.state.user.isFreeTrial : false;
    },
    trialDaysRemaining() {
      return this.$store.state.user ? this.$store.state.user.trialDaysRemaining : 0;
    },
    isSubscriptionOverdue() {
      return this.$store.state.user ? this.$store.state.user.subscriptionOverdue : false;
    },
    hasHadProfessionalSubscription() {
      return this.$store.state.user ? this.$store.state.user.hasHadProfessionalSubscription : false;
    },
    dateCreated() {
      const firestoreTimestamp = this.$store.state.user ? this.$store.state.user.dateCreated : null;
      return firestoreTimestamp ? firestoreTimestamp.toDate() : null;
    },
    lastLogin() {
      const firestoreTimestamp = this.$store.state.user ? this.$store.state.user.lastLogin : null;
      return firestoreTimestamp ? firestoreTimestamp.toDate() : null;
    },
    subscriptionRequired() {
      if (this.isLoadingUser || this.isSubscriptionOverdue) {
        return false;
      }

      return this.isTrialExpiredOrNotFirstDay();
    },
    showInitializationOverlay () {
      if (this.isProfessionalUser) {
        return false;
      }

      if (this.isTrialExpiredOrNotFirstDay()) {
        return false;
      }

      return !this.assistantId || !this.isBasicUser
    },
    truncatedUserEmail() {
      const maxLength = 24;
      if (this.userEmail && this.userEmail.length > maxLength) {
        return this.userEmail.substring(0, maxLength - 3) + '...';
      }
      return this.userEmail;
    },
    isSendDisabled() {
      return this.messageContent.trim().length === 0;
    },
    lastMessage() {
      if (this.conversation.length === 0) {
        return '';
      }
      return this.conversation[this.conversation.length - 1].content;
    }
  },
  watch: {
    isPaidUser(newVal) {
      console.log('isPaidUser:', newVal);
    },
    isFreeTrial(newVal) {
      console.log('isFreeTrial:', newVal);
    },
    isBasicUser(newVal) {
      console.log('isBasicUser:', newVal);
    },
    isProfessionalUser(newVal) {
      console.log('isProfessionalUser:', newVal);
    },
    isSubscriptionOverdue(newVal) {
      console.log('isSubscriptionOverdue:', newVal);
    },
    assistantId(newVal) {
      console.log('assistantId:', newVal);
    },
    lastMessage() {
      this.$nextTick(() => {
        if(!this.$refs.messageContainer) return;
        const container = this.$refs.messageContainer.$el;
        container.scrollTop = container.scrollHeight;
      });
    }
  }
}
</script>

<style scoped>
.top-navigation-bar {
  background-color: rgba(200, 200, 200, 0.2);
  backdrop-filter: blur(10px);
}

.navigation-button {
  margin-inline: 10px;
  color: rgb(var(--v-theme-primary));
}

.custom-background {
  background-color: rgb(var(--v-theme-background));
}

.main-container-basic-trial {
  display: flex;
  flex-direction: column;
  height: 95vh;
}

.main-container-professional-trial {
  display: flex;
  flex-direction: column;
  height: 90vh;
}

.main-container-subscribe,
.main-container-basic {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.main-container-professional {
  display: flex;
  flex-direction: column;
  height: 93.5vh;
}

.display-title {
  color: rgb(var(--v-theme-primary));
  font-weight: bold;
  font-size: 30px;
}

.display-text {
  color: rgb(var(--v-theme-primary));
  font-size: 16px;
  padding: 2px 0;
  text-align: center;
}

.breathing-image {
  height: 20vh;
  animation: breathingAnimation 3s ease-in-out infinite;
  margin-top: 40px;
}

.navigation-drawer {
  background-color: rgba(var(--v-theme-primary), 0.8);
  backdrop-filter: blur(10px);
}

:deep(.menu-card) {
  background-color: rgba(var(--v-theme-primary), 0.8) !important;
  backdrop-filter: blur(10px);
}

.default-container {
  flex-grow: 1;
  margin-inline: 10px;
  margin-bottom: 10px;
  margin-top: 5px;
}

.message-container {
  flex-grow: 1;
  overflow-y: auto;
  margin-inline: 10px;
  margin-bottom: 10px;
  margin-top: 5px;
}

:deep(.message-container) p {
  margin-inline: 20px;
  margin-block: 10px;
}

:deep(.message-container) h1 {
  margin-inline: 20px;
  margin-block: 20px;
}

:deep(.message-container) h2 {
  margin-inline: 20px;
  margin-block: 20px;
}

:deep(.message-container) h3 {
  margin-inline: 20px;
  margin-block: 10px;
}

:deep(.message-container) h4 {
  margin-inline: 20px;
  margin-block: 10px;
}

:deep(.message-container) h5 {
  margin-inline: 20px;
  margin-block: 10px;
}

:deep(.message-container) ul {
  margin-inline: 30px;
  margin-block: 10px;
}

:deep(.message-container) ol {
  margin-inline: 30px;
  margin-block: 10px;
}

:deep(.message-container) li {
  margin-inline: 30px;
  margin-block: 10px;
}

:deep(.message-container) hr {
  margin-inline: 20px;
  border: none;
  height: 2px;
  background-color: white;
}

:deep(.message-container) table {
  margin-inline: 30px;
  margin-block: 20px;
  width: 90%;
  border-collapse: collapse;
}

:deep(.message-container) th {
  padding-block: 10px;
  text-align: center;
  border: 1px solid white;
}

:deep(.message-container) td {
  padding-block: 5px;
  text-align: center;
  font-size: 14px;
  border: 1px solid white;
}

:deep(.message-container) pre {
  margin-inline: 20px;
  margin-block: 20px;
  padding: 10px;
  background-color: #282c34;
  color: #f8f8f2;
  border-radius: 5px;
  overflow: auto;
}

:deep(.message-container) code {
  width: 100%;
  background-color: #4b4d52;
  color: #f8f8f2;
  padding: 2px 4px;
  border-radius: 3px;
  font-family: 'Courier New', Courier, monospace;
  font-size: 14px;
}

:deep(.message-container) pre code {
  padding: 0;
}

:deep(.message-container) mjx-container {
  background-color: rgba(var(--v-theme-primary), 0.1);
  padding: 10px;
  margin: 5px;
  border-radius: 3px;
  display: inline-flex;
  justify-content: flex-start;
  width: auto;
  max-width: 100%;
  box-sizing: border-box;
}

:deep(.message-container) mjx-math {
  color: #f8f8f2;
  font-family: 'Times New Roman', Times, serif;
  font-weight: 400;
  white-space: normal;
}

:deep(.message-container) mjx-mtext,
:deep(.message-container) mjx-mn,
:deep(.message-container) mjx-mo {
  color: #f8f8f2;
  font-family: 'Times New Roman', Times, serif;
  font-weight: 400;
  padding-top: 5px;
  padding-bottom: 10px;
  word-wrap: break-word;
}

.message-container::-webkit-scrollbar {
  width: 5px;
}

.message-container::-webkit-scrollbar-track {
  background: rgba(var(--v-theme-primary), 0.1);
  border-radius: 10px;
}

.message-container::-webkit-scrollbar-thumb {
  background: rgba(var(--v-theme-primary), 0.3);
  border-radius: 10px;
}

.message-container::-webkit-scrollbar-thumb:hover {
  background: rgba(var(--v-theme-primary), 0.8);
}

.message-item {
  display: flex;
  align-items: flex-end;
  margin-bottom: 20px;
  width: 100%;
}

.message-box {
  padding: 10px;
  border-radius: 10px;
  width: 100%;
}

.user-message .message-box {
  background-color: rgba(var(--v-theme-primary), 0.8);
  color: white;
  margin-left: 10px; /* Space between avatar and message box */
  order: 1; /* Message box after avatar */
  border-bottom-left-radius: 0;
}

.assistant-message .message-box {
  background-color: rgba(var(--v-theme-secondary), 0.8);
  color: white;
  margin-right: 10px; /* Space between avatar and message box */
  order: 2; /* Message box before avatar */
  border-bottom-right-radius: 0;
}

.assistant-message .thinking-box {
  background-color: rgba(var(--v-theme-secondary), 0.8) !important;
  padding: 5px;
  min-height: 40px;
  width: 100px;
  margin-left: auto;
  border-radius: 8px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.thinking-message .assistant-avatar {
  opacity: 0.8;
}

.typing {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.typing span {
  display: block;
  height: 10px;
  width: 10px;
  background-color: rgba(var(--v-theme-background));
  border-radius: 50%;
  margin: 0 2px;
  animation: blink 1.5s infinite;
  animation-fill-mode: both;
}

.typing span:nth-child(2) {
  animation-delay: 0.2s;
}

.typing span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes blink {
  0% {
    opacity: 0.1;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
  }
}

.avatar {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.user-avatar {
  order: 0; /* Avatar before message box */
}

.assistant-avatar {
  order: 3; /* Avatar after message box */
}

:deep(.footnotes) {
  margin-block: 20px;
  margin-inline: 20px;
  background-color: rgba(var(--v-theme-background), 0.2);
  border-radius: 5px;
  padding: 10px;
  padding-left: 20px;
}

:deep(.footnote-ref a) {
  color: rgba(var(--v-theme-primary)) !important;
}

:deep(.footnotes-content) {
  margin-block: 20px;
  color: #f8f8f2;
}

:deep(.footnotes-content ol) {
  margin-inline: 10px;
  margin-block: 5px;
}

:deep(.footnotes summary) {
  cursor: pointer;
}

:deep(.footnotes summary::marker) {
  content: none;
}

.input-container {
  align-self: center;
  justify-content: center;
  width: 100%;
  max-height: 100px;
}

.subtext-content {
  align-self: center;
  justify-content: center;
  text-align: center;
  padding: 0;
  color: rgba(var(--v-theme-primary));
}

.textarea-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.flex-container {
  display: flex;
  position: relative;
  width: 100%;
}

.text-area {
  flex-grow: 1;
  cursor: text;
}

:deep(.v-field__input::-webkit-scrollbar) {
  display: none;
}

:deep(.v-field) {
  padding-right: 55px !important;
  color: rgba(var(--v-theme-primary));
}

.send-button {
  height: 30px;
  position: absolute;
  cursor: pointer;
  padding-top: 3px;
  padding-left: 10px;
}

.send-button-placeholder {
  height: 30px;
  position: absolute;
  padding-top: 3px;
  padding-left: 20px;
}

.message-subtext {
  font-weight: 300;
  font-size: 14px;
  color: rgba(var(--v-theme-primary));
  text-align: center;
  padding-top: 2px;
}

.prompt-row {
  height: 20px;
}

.prompt-button {
  font-weight: 300;
  font-size: 14px;
  color: rgba(var(--v-theme-primary));
  text-align: center;
}

.trial-item {
  display: flex;
  align-items: center;
  margin-block: 5px;
  font-weight: 500;
}

.checkout-loading {
  margin-left: 64px;
}

.initializing-overlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(var(--v-theme-primary), 0.8);
  backdrop-filter: blur(20px);
}

.initializing-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: rgba(var(--v-theme-background));
  background-color: transparent;
}

.subscription-overlay {
  margin-left: 64px;
  align-items: center;
  justify-content: center;
}

.subscription-card {
  color: rgba(var(--v-theme-primary));
  background-color: rgba(var(--v-theme-background));
  width: 40vw;
}

.subscribe-button {
  margin-top: 20px;
  color: rgba(var(--v-theme-background));
  background-color: rgba(var(--v-theme-primary));
}

.logout-button {
  margin-top: 10px;
  margin-bottom: 20px;
}

@keyframes breathingAnimation {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
}

@media (max-width: 1024px) {
  .main-container-subscribe,
  .main-container-basic,
  .main-container-professional {
    height: 95vh;
  }
  .main-container-basic-trial,
  .main-container-professional-trial {
    height: 90vh;
  }

  .checkout-loading {
    margin-left: 0px;
  }

  .subscription-overlay {
    margin-left: 0px;
  }

  .subscription-card {
    width: 80vw;
  }

  :deep(.message-container pre) {
    margin-inline: 10px;
  }

  :deep(.message-container code) {
    white-space: normal;
    text-wrap: break-word;
  }
}

@media (max-width: 600px) {
  .main-container-subscribe,
  .main-container-basic,
  .main-container-professional {
    height: 93.5vh;
  }
  .main-container-basic-trial,
  .main-container-professional-trial {
    height: 88.5vh;
  }

  .display-title {
    font-size: 20px;
  }

  .display-text {
    font-size: 14px;
  }

  .avatar {
    height: 20px;
    width: 20px;
  }

  .message-container {
    font-size: 14px;
  }

  :deep(.message-container p),
  :deep(.message-container h1),
  :deep(.message-container h2),
  :deep(.message-container h3),
  :deep(.message-container h4),
  :deep(.message-container h5) {
    margin-inline: 10px;
  }

  :deep(.message-container ul),
  :deep(.message-container ol),
  :deep(.message-container li) {
    margin-inline: 12.5px;
  }

  :deep(.message-container table) {
    margin-inline: 15px;
  }

  :deep(.message-container th),
  :deep(.message-container td) {
    font-size: 12px;
  }

  .message-container::-webkit-scrollbar {
    display: none;
  }

  :deep(.footnotes) {
    margin-inline: 10px;
  }

  .navigation-button {
    margin-inline: 5px;
  }

  .subtext-content {
    font-size: 10px;
  }

  .checkout-loading {
    margin-left: 0px;
  }

  .subscription-overlay {
    margin-left: 0px;
  }

  .subscription-card {
    width: 80vw;
  }
}
</style>
