// firebaseService.js

import { db } from "../../firebaseConfig";
import { storage } from "../../firebaseConfig";
import { 
  doc, 
  getDoc, 
  getDocs,
  setDoc,
  deleteDoc,
  collection,
  updateDoc, 
  arrayUnion,
  Timestamp,
  query,
  where,
  orderBy,
  onSnapshot
} from "firebase/firestore";
import { ref, uploadBytes, deleteObject } from "firebase/storage";
import authService from '../services/authService';
import { Sentry } from '@/plugins/sentry.js';
import { analytics } from '../../firebaseConfig';
import { logEvent } from 'firebase/analytics';

// Function to create a profile for a new user
async function createUserProfile(user) {
  
  try {
    // Get user record
    const userRef = doc(db, "users", user.uid);
    const docSnap = await getDoc(userRef);

    // User records with profile
    if (docSnap.exists() && docSnap.data().dateCreated) {
      return;
    }

    // No user record (highly unlikely)
    if (!docSnap.exists()) {
      const now = new Date();
      const userProfile = {
        email: user.email || '',
        name: user.displayName || '',
        assistantId: '',
        dateCreated: now,
        overrideSubscription: false,
        lastLogin: now,
      };
      await setDoc(userRef, userProfile);
    }

    // User record without profile
    if (docSnap.exists() && !docSnap.data().dateCreated) {
      const now = new Date();
      await updateDoc(userRef, {
        email: user.email || '',
        name: user.displayName || '',
        overrideSubscription: false,
        dateCreated: now,
        lastLogin: now
      });
    }

    await updateTemporaryRecords(user.email, user.uid);
    logEvent(analytics, 'new_user_created', { userId: user.uid });
  } catch (error) {
    Sentry.captureMessage("Error creating user profile");
    Sentry.captureException(error);
    console.error("Error creating user profile:", error);
  }
}

// Helper function to update temporary student records with authenticated student UID
async function updateTemporaryRecords(email, uid) {
  const instructorsRef = collection(db, "instructors");

  try {
    const instructorsSnapshot = await getDocs(instructorsRef);

    for (const instructorDoc of instructorsSnapshot.docs) {
      const instructorUid = instructorDoc.id;
      
      if (!instructorDoc.exists()) {
        console.error(`Instructor document ${instructorUid} does not exist.`);
        continue;
      }

      const tempStudentsRef = collection(db, "instructors", instructorUid, "activeStudents");
      const q = query(tempStudentsRef, where("email", "==", email), where("isTemporary", "==", true));
      const tempStudentsSnapshot = await getDocs(q);

      if (tempStudentsSnapshot.empty) {
        console.log(`No temporary students found for instructor: ${instructorUid} with email: ${email}`);
      } else {
        for (const tempStudentDoc of tempStudentsSnapshot.docs) {
          const tempStudentData = tempStudentDoc.data();
          const newStudentRef = doc(db, "instructors", instructorUid, "activeStudents", uid);

          await setDoc(newStudentRef, {
            ...tempStudentData,
            id: uid,
            isTemporary: false
          });

          // Initialize the new student document in the lessons collection if it doesn't exist
          const newStudentLessonsDocRef = doc(db, "lessons", uid);
          const newStudentLessonsDoc = await getDoc(newStudentLessonsDocRef);
          if (!newStudentLessonsDoc.exists()) {
            await setDoc(newStudentLessonsDocRef, {
              studentId: uid
            });
          }

          // Update lessons collection
          const lessonsRef = collection(db, "lessons", tempStudentDoc.id, "flights");
          const lessonsSnapshot = await getDocs(lessonsRef);

          for (const lessonDoc of lessonsSnapshot.docs) {
            const lessonData = lessonDoc.data();
            const newLessonRef = doc(db, "lessons", uid, "flights", lessonDoc.id);
            await setDoc(newLessonRef, lessonData);
            await deleteDoc(lessonDoc.ref);
          }

          // Remove the temporary student's lessons document
          const tempStudentLessonsDocRef = doc(db, "lessons", tempStudentDoc.id);
          await deleteDoc(tempStudentLessonsDocRef);
          await deleteDoc(tempStudentDoc.ref);
        }
        logEvent(analytics, 'temp_student_record_updated', { tempStudentId: uid });
      }
    }
  } catch (error) {
    Sentry.captureMessage("Error updating temporary student records");
    Sentry.captureException(error);
    console.error("Error updating temporary student records:", error);
  }
}

// Function to get user profile information from Firestore
async function fetchUserProfile(uid) {
  const userRef = doc(db, "users", uid);

  try {
    const docSnap = await getDoc(userRef);
    const authUser = authService.state.user;

    if (!docSnap.exists()) {
      throw new Error('User profile does not exist.');
    }

    // Prepare the user data
    let userData = docSnap.data();

    // Check if photoUrl is present, if not add from authService user and update Firestore
    if (!userData.photoUrl && authUser.photoURL) {
      await updateDoc(userRef, { photoUrl: authUser.photoURL });
      // Update local userData object to reflect the change
      userData.photoUrl = authUser.photoURL;
    }

    return userData;

  } catch (error) {
    Sentry.captureMessage("Error fetching user profile");
    Sentry.captureException(error);
    console.error("Error fetching user profile:", error);
    throw error;
  }
}

// Function to update user profile with given fields
async function updateUserProfile(userId, fieldsToUpdate) {
  const userRef = doc(db, "users", userId);

  try {
    await updateDoc(userRef, fieldsToUpdate);
    logEvent(analytics, 'user_profile_updated', { userId: userId });
  } catch (error) {
    Sentry.captureMessage("Error updating user profile");
    Sentry.captureException(error);
    console.error("Error updating user profile:", error);
  }
}

// Function to wait for the creation of a Firestore document
const waitForSubscriptionCreation = (userId, subscriptionId) => {
  return new Promise((resolve, reject) => {
    try {
      const subscriptionDocRef = doc(db, `users/${userId}/subscriptions`, subscriptionId);

      const unsubscribe = onSnapshot(subscriptionDocRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          unsubscribe(); // Clean up the listener
          resolve(true); // Resolve the promise when the document is detected
        }
      }, (error) => {
        unsubscribe(); // Clean up on error
        reject(error); // Reject the promise if there's an error
      });
    } catch (error) {
      Sentry.captureMessage("Error in waitForSubscriptionCreation");
      Sentry.captureException(error);
      reject(error); // Reject the promise if there's an unexpected error
    }
  });
};

// Function to check for active subscriptions
async function checkUserSubscription(uid) {
  const userRef = doc(db, "users", uid);
  
  try {
    const userDoc = await getDoc(userRef);

    // Check for subscription override
    if (userDoc.exists() && userDoc.data().overrideSubscription === true) {
      return true;
    }

    // Check for active subscriptions
    const subscriptionsRef = collection(db, `users/${uid}/subscriptions`);
    const q = query(subscriptionsRef, where("status", "in", ["active"]));
    const querySnapshot = await getDocs(q);

    // Return true if there's at least one active subscription
    return !querySnapshot.empty;
  } catch (error) {
    Sentry.captureMessage("Error checking user subscription");
    Sentry.captureException(error);
    console.error("Error checking user subscription:", error);
    return false;
  }
}

// Function to check user's subscribed product(s)
async function checkUserProducts(uid) {
  const BASIC_PRODUCT = process.env.VUE_APP_BASIC_PRODUCT;
  const PROFESSIONAL_PRODUCT = process.env.VUE_APP_PROFESSIONAL_PRODUCT;

  // Define user document reference
  const userRef = doc(db, "users", uid);

  try {
    // Fetch user document
    const userDoc = await getDoc(userRef);

    // Check for subscriptionOverride and return Professional (active) if one exists
    if (userDoc.exists() && userDoc.data().overrideSubscription === true) {
      return {
        productName: 'Professional',
        isFreeTrial: false,
        trialDaysRemaining: 0,
        subscriptionOverdue: false
      };
    }

    // Query the subscriptions subcollection for any active, trialing, or past_due subscriptions
    const subscriptionsRef = collection(db, `users/${uid}/subscriptions`);
    const q = query(subscriptionsRef, where("status", "in", ["active", "trialing", "past_due"]));
    const querySnapshot = await getDocs(q);

    const productNames = {
      [BASIC_PRODUCT]: 'Basic',
      [PROFESSIONAL_PRODUCT]: 'Professional'
    };

    // Initialize variables for returning trial info
    let isFreeTrial = false;
    let trialDaysRemaining = 0;
    let subscriptionOverdue = false;

    // Retrieve the first active, trialing, or past_due product found
    for (const subscriptionDoc of querySnapshot.docs) {
      const data = subscriptionDoc.data();

      // Check the items array to find the associated product ID (i.e. type)
      if (data.items && data.items.length > 0) {
        for (const item of data.items) {
          if (item.plan && item.plan.product) {
            const productPath = item.plan.product;
            const productId = productPath.split('/').pop();
            const productName = productNames[productId] || 'Unknown Product';

            // Check if the subscription is past due
            if (data.status === 'past_due') {
              return {
                productName: productName,
                isFreeTrial: false,
                trialDaysRemaining: 0,
                subscriptionOverdue: true
              };
            }

            // Calculate days remaining for the trial
            if (data.status === 'trialing') {
              isFreeTrial = true;
              const trialEndDate = new Date(data.trial_end.seconds * 1000);
              const today = new Date();
              trialDaysRemaining = Math.max(0, Math.ceil((trialEndDate - today) / (1000 * 60 * 60 * 24))); // Calculate days remaining
            }

            // Return the subscription type and trial information
            return {
              productName: productName,
              isFreeTrial: isFreeTrial,
              trialDaysRemaining: trialDaysRemaining,
              subscriptionOverdue: subscriptionOverdue
            };
          }
        }
      }
    }

    // If no active subscriptions, trials, or past_due statuses found, return none
    return {
      productName: 'None',
      isFreeTrial: false,
      trialDaysRemaining: 0,
      subscriptionOverdue: false
    };
  } catch (error) {
    Sentry.captureMessage("Error checking user products");
    Sentry.captureException(error);
    console.error("Error checking user products:", error);
    return {
      productName: 'None',
      isFreeTrial: false,
      trialDaysRemaining: 0,
      subscriptionOverdue: false
    };
  }
}

// Function to check if the user has had a Professional subscription before
async function hasUserHadProfessionalSubscription(uid) {
  const PROFESSIONAL_PRODUCT = process.env.VUE_APP_PROFESSIONAL_PRODUCT;
  const subscriptionsRef = collection(db, `users/${uid}/subscriptions`);
  
  try {
    if (!PROFESSIONAL_PRODUCT) {
      throw new Error('PROFESSIONAL_PRODUCT environment variable is not defined');
    }

    // Query the subscriptions subcollection for any subscriptions
    const q = query(subscriptionsRef);
    const querySnapshot = await getDocs(q);
    
    // Filter for Professional subscriptions in the application code
    const professionalSubscriptions = querySnapshot.docs.filter((doc) => {
      const data = doc.data();
      return Array.isArray(data.items) && data.items.some(item => {
        return item.plan && item.plan.product === PROFESSIONAL_PRODUCT;
      });
    });

    // Check if there are any Professional subscriptions in the past
    return professionalSubscriptions.length > 0;
  } catch (error) {
    Sentry.captureMessage("Error checking user's past Professional subscriptions");
    Sentry.captureException(error);
    console.error("Error checking user's past Professional subscriptions:", error);
    return false;
  }
}

// Function to add a new thread for the user
async function updateUserWithThreadId(userId, threadId) {
  const userThreadsCollectionRef = collection(db, "threads", userId, "userThreads");
  const threadDocRef = doc(userThreadsCollectionRef, threadId);
  const now = Timestamp.now();

  try {
    await setDoc(threadDocRef, {
      messages: [],
      threadCreated: now
    });
  } catch (error) {
    Sentry.captureMessage("Error updating user with thread");
    Sentry.captureException(error);
    console.error("Error updating user with thread:", error);
  }
}

// Function to add a message to the thread
async function addMessageToThread(userId, threadId, messageContent, messageType, citations = []) {
  const userThreadsCollectionRef = collection(db, "threads", userId, "userThreads");
  const threadDocRef = doc(userThreadsCollectionRef, threadId);
  const now = Timestamp.now();

  try {
    await updateDoc(threadDocRef, {
      messages: arrayUnion({
        type: messageType,
        message: messageContent,
        timestamp: now,
        citations: citations
      }),
    });
  } catch (error) {
    Sentry.captureMessage("Error adding message to thread");
    Sentry.captureException(error);
    console.error("Error adding message to thread:", error);
  }
}

// Function to get messages from a thread
async function getMessagesFromThread(userId, threadId) {
  const userThreadsCollectionRef = collection(db, "threads", userId, "userThreads");
  const threadDocRef = doc(userThreadsCollectionRef, threadId);

  try {
    const docSnapshot = await getDoc(threadDocRef);
    if (docSnapshot.exists()) {
      return docSnapshot.data().messages || [];
    } else {
      return [];
    }
  } catch (error) {
    Sentry.captureMessage("Error getting messages from thread");
    Sentry.captureException(error);
    console.error("Error getting messages from thread:", error);
    return [];
  }
}

// Function to fetch user chat threads, including the latest message for each thread
// TODO - Replace with new REST endpoint
async function fetchUserChatThreads(userUid) {
  try {
    const userThreadsCollectionRef = collection(db, "threads", userUid, "userThreads");
    const q = query(userThreadsCollectionRef, orderBy("threadCreated", "desc"));

    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      return querySnapshot.docs.map(doc => {
        const data = doc.data();
        const firstMessage = data.messages && data.messages.length > 0 ? data.messages[0] : {};
        return {
          threadId: doc.id,
          threadCreated: data.threadCreated,
          firstMessage: firstMessage
        };
      });
    } else {
      return [];
    }
  } catch (error) {
    Sentry.captureMessage("Error fetching user chat threads");
    Sentry.captureException(error);
    console.error("Error fetching user chat threads:", error);
    return [];
  }
}

// Function to check if a student's email exists in the database
async function checkIfEmailExists(email, instructorUid) {
  const usersRef = collection(db, "users");
  const instructorRef = doc(db, `instructors/${instructorUid}`);
  const activeStudentsRef = collection(instructorRef, "activeStudents");

  try {
    // Check in 'users' collection
    const usersQuery = query(usersRef, where("email", "==", email));
    const usersSnapshot = await getDocs(usersQuery);
    const emailExistsInUsers = !usersSnapshot.empty;

    // Check in instructor's 'activeStudents' collection
    const activeStudentsQuery = query(activeStudentsRef, where("email", "==", email));
    const activeStudentsSnapshot = await getDocs(activeStudentsQuery);
    const emailExistsInActiveStudents = !activeStudentsSnapshot.empty;

    return {
      emailExistsInUsers,
      emailExistsInActiveStudents
    };
  } catch (error) {
    Sentry.captureMessage("Error checking if email exists");
    Sentry.captureException(error);
    console.error("Error checking if email exists:", error);
    return {
      emailExistsInUsers: false,
      emailExistsInActiveStudents: false
    };
  }
}

// Function to get student information by email
async function getStudentInfo(email) {
  const usersRef = collection(db, "users");
  const q = query(usersRef, where("email", "==", email));

  try {
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
      return null;
    }
    const studentDoc = querySnapshot.docs[0];
    const studentData = studentDoc.data();

    const fullName = studentData.name || null;
    const photoUrl = studentData.photoUrl || null;
    const phoneNumber = studentData.phone || null;
    const aircraft = studentData.aircraft || [];
    const certificates = studentData.certificates || [];
    const endorsements = studentData.endorsements || [];
    const ratings = studentData.ratings || [];
    const address = studentData.address || null;
    const medical = studentData.medical || null;
    const medicalExpiration = studentData.medicalExpiration || null;

    return {
      uid: studentDoc.id,
      email: studentData.email,
      fullName: fullName,
      photoUrl: photoUrl,
      phoneNumber: phoneNumber,
      aircraft: aircraft,
      certificates: certificates,
      endorsements: endorsements,
      ratings: ratings,
      address: address,
      medical: medical,
      medicalExpiration: medicalExpiration
    };
  } catch (error) {
    Sentry.captureMessage("Error getting student information");
    Sentry.captureException(error);
    console.error("Error getting student information:", error);
    return null;
  }
}

// Function to add a student to an instructor's activeStudents subcollection
async function addStudentToInstructor(instructorUid, studentInfo, selectedTrainingCourse, selectedTrainingPath, selectedStandard) {
  const instructorRef = doc(db, "instructors", instructorUid);
  const instructorDoc = await getDoc(instructorRef);

  // Initialize the instructor document if it doesn't exist
  if (!instructorDoc.exists()) {
    await setDoc(instructorRef, {
      instructorId: instructorUid
    });
  }

  const studentRef = doc(db, "instructors", instructorUid, "activeStudents", studentInfo.uid);

  const studentData = {
    id: studentInfo.uid || '',
    email: studentInfo.email,
    photoUrl: studentInfo.photoUrl || '',
    fullName: studentInfo.fullName || '',
    phoneNumber: studentInfo.phoneNumber || '',
    aircraft: studentInfo.aircraft || [],
    certificates: studentInfo.certificates || [],
    endorsements: studentInfo.endorsements || [],
    ratings: studentInfo.ratings || [],
    trainingCourse: selectedTrainingCourse,
    trainingPath: selectedTrainingPath,
    acsStandard: selectedStandard,
    address: studentInfo.address || '',
    medical: studentInfo.medical || null,
    medicalExpiration: studentInfo.medicalExpiration || '',
    isTemporary: false
  };

  try {
    await setDoc(studentRef, studentData);
    logEvent(analytics, 'student_added_to_instructor', { instructorId: instructorUid});
    return true;
  } catch (error) {
    Sentry.captureMessage("Error adding student to instructor");
    Sentry.captureException(error);
    console.error("Error adding student to instructor:", error);
    throw error;
  }
}

// Function to add a student with a temporary ID to an instructor's activeStudents subcollection
async function addTemporaryStudent(instructorUid, studentEmail, studentName, selectedTrainingCourse, selectedTrainingPath, selectedStandard, tempId) {
  const instructorRef = doc(db, "instructors", instructorUid);
  const instructorDoc = await getDoc(instructorRef);

  // Initialize the instructor document if it doesn't exist
  if (!instructorDoc.exists()) {
    await setDoc(instructorRef, {
      instructorId: instructorUid
    });
  }

  const studentRef = doc(db, "instructors", instructorUid, "activeStudents", tempId);

  const studentData = {
    id: tempId,
    email: studentEmail,
    fullName: studentName,
    trainingCourse: selectedTrainingCourse,
    trainingPath: selectedTrainingPath,
    acsStandard: selectedStandard,
    isTemporary: true
  };

  try {
    await setDoc(studentRef, studentData);
    logEvent(analytics, 'student_added_to_instructor', { instructorId: instructorUid});
    return true;
  } catch (error) {
    Sentry.captureMessage("Error adding temporary student to instructor");
    Sentry.captureException(error);
    console.error("Error adding temporary student to instructor:", error);
    throw error;
  }
}

// Function to load students for the instructor's dashboard
async function fetchStudentsForInstructor(instructorUid) {
  const studentsCollectionRef = collection(db, "instructors", instructorUid, "activeStudents");

  try {
    const querySnapshot = await getDocs(studentsCollectionRef);
    const students = [];

    for (const documentSnapshot of querySnapshot.docs) {
      let student = documentSnapshot.data();
      student.id = documentSnapshot.id;

      if (student.isTemporary) {
        // For temporary students, directly add their data to the students array
        students.push(student);
      } else {
        // For authenticated students, fetch additional information from the users collection
        const studentInfo = await getStudentInfo(student.email);

        if (studentInfo) {
          let hasChanged = false;

          // Check if the student name has changed
          if (student.fullName !== studentInfo.fullName) {
            student.fullName = studentInfo.fullName;
            hasChanged = true;
          }

          // Check if the student photoUrl has changed
          if (student.photoUrl !== studentInfo.photoUrl) {
            student.photoUrl = studentInfo.photoUrl;
            hasChanged = true;
          }

          // Check if the phone number has changed
          if (student.phoneNumber !== studentInfo.phoneNumber) {
            student.phoneNumber = studentInfo.phoneNumber;
            hasChanged = true;
          }

          // Check if the medical has changed
          if (student.medical !== studentInfo.medical) {
            student.medical = studentInfo.medical;
            hasChanged = true;
          }

          // Check other relevant fields for updates
          const fieldsToUpdate = [
            'aircraft',
            'certificates',
            'endorsements',
            'ratings',
            'address',
            'medicalExpiration'
          ];
          fieldsToUpdate.forEach(field => {
            if (JSON.stringify(student[field]) !== JSON.stringify(studentInfo[field])) {
              student[field] = studentInfo[field];
              hasChanged = true;
            }
          });

          // Reflect changes in Firestore if any field has changed
          if (hasChanged) {
            const studentDocRef = doc(db, "instructors", instructorUid, "activeStudents", documentSnapshot.id);
            await updateDoc(studentDocRef, student);
          }

          students.push(student);
        } else {
          console.error("Student info not found for email:", student.email);
        }
      }
    }

    // Sort students by 'fullName', handling potential null or undefined values and placing them last
    students.sort((a, b) => {
      if (!a.fullName) return 1;
      if (!b.fullName) return -1;
      return a.fullName.localeCompare(b.fullName);
    });

    return students;
  } catch (error) {
    Sentry.captureMessage("Error fetching students for instructor");
    Sentry.captureException(error);
    console.error("Error fetching students for instructor:", error);
    return [];
  }
}

// Function to update training path of a specific student
async function updateStudentTrainingPath(instructorUid, studentUid, newTrainingPath) {
  const studentDocRef = doc(db, "instructors", instructorUid, "activeStudents", studentUid);

  try {
    await updateDoc(studentDocRef, { trainingPath: newTrainingPath });
    logEvent(analytics, 'student_training_path_updated', { instructorId: instructorUid});
    return true;
  } catch (error) {
    Sentry.captureMessage("Error updating student training path");
    Sentry.captureException(error);
    console.error("Error updating student training path:", error);
    return false;
  }
}

// Function to update training course of a specific student
async function updateStudentTrainingCourse(instructorUid, studentUid, newTrainingCourse) {
  const studentDocRef = doc(db, "instructors", instructorUid, "activeStudents", studentUid);

  try {
    await updateDoc(studentDocRef, { trainingCourse: newTrainingCourse });
    logEvent(analytics, 'student_training_course_updated', { instructorId: instructorUid});
    return true;
  } catch (error) {
    Sentry.captureMessage("Error updating student training course");
    Sentry.captureException(error);
    console.error("Error updating student training course:", error);
    return false;
  }
}

// Function to update or create ACS standards of a specific student
async function updateStudentStandard(instructorUid, studentUid, newStandard) {
  const studentDocRef = doc(db, "instructors", instructorUid, "activeStudents", studentUid);

  try {
    // Check if the document exists and if it has the studentStandard field
    const studentDoc = await getDoc(studentDocRef);
    if (studentDoc.exists()) {
      const studentData = studentDoc.data();
      if (!studentData.acsStandard) {
        // If the field doesn't exist, create it
        await setDoc(studentDocRef, { acsStandard: newStandard }, { merge: true });
      } else {
        // If the field exists, update it
        await updateDoc(studentDocRef, { acsStandard: newStandard });
      }
    } else {
      console.warn("Student document does not exist and will not be created.");
      return false;
    }
    logEvent(analytics, 'student_acs_standard_updated', { instructorId: instructorUid });
    return true;
  } catch (error) {
    Sentry.captureMessage("Error updating student ACS standard");
    Sentry.captureException(error);
    console.error("Error updating student ACS standard:", error);
    return false;
  }
}

// Function to fetch all standards and their associated topics
async function fetchAcsStandards() {
  const standardsCollectionRef = collection(db, "standards");

  try {
    const standardsSnapshot = await getDocs(standardsCollectionRef);
    const standardsData = await Promise.all(standardsSnapshot.docs.map(async (standardDoc) => {
      const standardData = standardDoc.data();

      // Fetch topics for this standard
      const topicsCollectionRef = collection(db, "standards", standardDoc.id, "topics");
      const topicsSnapshot = await getDocs(topicsCollectionRef);

      // Map and sort topics based on their ID
      const topicsArray = topicsSnapshot.docs
        .map(topicDoc => ({
          id: topicDoc.id,
          ...topicDoc.data(),
        }))
        .sort((a, b) => {
          const aIdNumber = parseInt(a.id.split('-')[0], 10);
          const bIdNumber = parseInt(b.id.split('-')[0], 10);
          return aIdNumber - bIdNumber;
        });

      return { id: standardDoc.id, ...standardData, topics: topicsArray };
    }));

    // Sort the standards alphabetically by name
    const sortedStandardsData = standardsData.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });

    return sortedStandardsData;
  } catch (error) {
    Sentry.captureMessage("Error fetching ACS standards");
    Sentry.captureException(error);
    console.error("Error fetching ACS standards:", error);
    return [];
  }
}

// Function to record a flight lesson
async function recordFlightLesson(studentUid, lessonId, flightLesson) {
  const lessonRef = doc(db, "lessons", studentUid, "flights", lessonId);
  const studentDocRef = doc(db, "lessons", studentUid);

  // Initialize the student document if it doesn't exist
  const studentDoc = await getDoc(studentDocRef);
  if (!studentDoc.exists()) {
    await setDoc(studentDocRef, {
      studentId: studentUid
    });
  }

  // Include the studentId in the flight lesson data
  const flightLessonWithStudentId = {
    ...flightLesson
  };

  try {
    await setDoc(lessonRef, flightLessonWithStudentId);
    logEvent(analytics, 'lesson_recorded', { studentId: studentUid, lessonId: lessonId});
    return true;
  } catch (error) {
    Sentry.captureMessage("Error recording lesson");
    Sentry.captureException(error);
    console.error("Error recording lesson:", error);
    throw error;
  }
}

// Function to update a flight lesson
async function updateFlightLesson(studentUid, flightLesson, lessonId) {
  const lessonRef = doc(db, "lessons", studentUid, "flights", lessonId);

  try {
    const docSnapshot = await getDoc(lessonRef);
    if (docSnapshot.exists()) {
      // The document exists, so we can update it
      await updateDoc(lessonRef, flightLesson);
    } else {
      // The document does not exist, so we need to create it
      await setDoc(lessonRef, flightLesson);
    }
    logEvent(analytics, 'lesson_edited', { studentId: studentUid, lessonId: lessonId});
    return true;
  } catch (error) {
    Sentry.captureMessage("Error updating lesson");
    Sentry.captureException(error);
    console.error("Error updating lesson:", error);
    throw error;
  }
}

// Function to delete a flight lesson
async function deleteFlightLesson(studentUid, lessonId) {
  const lessonRef = doc(db, "lessons", studentUid, "flights", lessonId);

  try {
    await deleteDoc(lessonRef);
    logEvent(analytics, 'lesson_deleted', { studentId: studentUid, lessonId: lessonId});
    return true;
  } catch (error) {
    Sentry.captureMessage("Error deleting lesson");
    Sentry.captureException(error);
    console.error("Error deleting lesson:", error);
    throw error;
  }
}

// Function to fetch flight lessons for a student
async function fetchStudentFlights(studentUid) {
  const lessonsCollectionRef = collection(db, "lessons", studentUid, "flights");
  
  try {
    const q = query(lessonsCollectionRef, orderBy("timestamp", "desc"));
    const querySnapshot = await getDocs(q);
    const flights = [];

    for (const docSnapshot of querySnapshot.docs) {
      const flightData = docSnapshot.data();
      const instructorUid = flightData.instructor;

      // Fetch the instructor's name from the users collection
      if (instructorUid) {
        const instructorProfile = await fetchUserProfile(instructorUid);
        flightData.instructorName = `${instructorProfile.name}`;
      } else {
        flightData.instructorName = "No instructor assigned";
      }

      flights.push({ id: docSnapshot.id, ...flightData });
    }

    logEvent(analytics, 'student_lessons_fetched', { studentId: studentUid});

    return flights;
  } catch (error) {
    Sentry.captureMessage("Error fetching lessons for student");
    Sentry.captureException(error);
    console.error("Error fetching lessons for student:", studentUid, error);
    return [];
  }
}

// Function to fetch last checked flights timestamp
async function fetchLastCheckedFlights(userUid) {
  const docRef = doc(db, "lessons", userUid);

  try {
    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
      const data = docSnapshot.data();
      return data.lastCheckedFlights ? new Date(data.lastCheckedFlights.seconds * 1000) : null;
    } else {
      return null;
    }
  } catch (error) {
    Sentry.captureMessage("Error fetching last checked flights timestamp");
    Sentry.captureException(error);
    console.error("Error fetching last checked flights timestamp:", error);
    return null;
  }
}

// Function to update last checked flights timestamp
async function updateLastCheckedFlights(userUid) {
  const docRef = doc(db, "lessons", userUid);

  try {
    await setDoc(docRef, { lastCheckedFlights: new Date() }, { merge: true });
  } catch (error) {
    Sentry.captureMessage("Error updating last checked flights timestamp");
    Sentry.captureException(error);
    console.error("Error updating last checked flights timestamp:", error);
  }
}

// Function to report an inaccurate response
async function reportInaccurateResponse(reportId, threadId, message, user) {
  const reportRef = doc(db, "reportedResponses", reportId);
  
  // Initialize the report document if it doesn't exist
  const reportDoc = await getDoc(reportRef);
  if (!reportDoc.exists()) {
    await setDoc(reportRef, { reportId: reportId });
  }

  const reportData = {
    reportId: reportId,
    threadId: threadId,
    userId: user,
    message: message,
    timestamp: new Date()
  };

  try {
    await updateDoc(reportRef, reportData);
    logEvent(analytics, 'inaccurate_response_reported', { reportId: reportId });
    return true;
  } catch (error) {
    Sentry.captureMessage("Error reporting inaccurate response");
    Sentry.captureException(error);
    console.error("Error reporting inaccurate response:", error);
    throw error;
  }
}

// Function to upload a file
async function uploadFileToStorage(userId, file) {
  try {
    const fileRef = ref(storage, `corpus/user/${userId}/${file.name}`);
    await uploadBytes(fileRef, file);
    logEvent(analytics, 'custom_file_uploaded', { userUid: userId});
    return { success: true, filename: file.name };
  } catch (error) {
    Sentry.captureMessage("Error uploading custom file");
    Sentry.captureException(error);
    console.error("Error uploading file:", error);
    throw error;
  }
}

// Function to delete a file
async function deleteFileFromStorage(userId, filename) {
  try {
    const fileRef = ref(storage, `corpus/user/${userId}/${filename}`);
    await deleteObject(fileRef);
    logEvent(analytics, 'custom_file_deleted', { userUid: userId});
    return { success: true, filename };
  } catch (error) {
    Sentry.captureMessage("Error deleting custom file");
    Sentry.captureException(error);
    console.error("Error deleting file:", error);
    throw error;
  }
}

export { 
  createUserProfile,
  fetchUserProfile,
  updateUserProfile,
  waitForSubscriptionCreation,
  checkUserSubscription,
  checkUserProducts, 
  hasUserHadProfessionalSubscription,
  updateUserWithThreadId,
  addMessageToThread,
  getMessagesFromThread,
  fetchUserChatThreads,
  checkIfEmailExists,
  getStudentInfo,
  addStudentToInstructor,
  addTemporaryStudent,
  fetchStudentsForInstructor,
  updateStudentTrainingPath,
  updateStudentTrainingCourse,
  updateStudentStandard,
  fetchAcsStandards,
  recordFlightLesson,
  updateFlightLesson,
  deleteFlightLesson,
  fetchStudentFlights,
  fetchLastCheckedFlights,
  updateLastCheckedFlights,
  reportInaccurateResponse,
  uploadFileToStorage,
  deleteFileFromStorage
}
